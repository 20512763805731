<template>
  <div>
    <div class="mt-2">
      <b-card>
        <!-- <Loading v-if="loadingState" /> -->
        <!-- <b-card-text v-if="hiddenTable"> -->
        <b-card-text>
          <b-row>
            <b-col cols="12" class="mt-1">
              <b-row align-h="center" align-v="center">
                <b-col cols="12" lg="6">
                  <div class="d-flex smallGap">
                    <b-button
                        v-for="(button, index) in categoryButton"
                        :key="index"
                        v-ripple.400
                        size="sm"
                        class="text-white"
                        :class="
                        categoryValue === button.value ? 'custom-shadow' : ''
                      "
                        @click="selectedStatus(button)"
                        :variant="button.variant"
                        v-text="$t(button.label)"
                    />
                  </div>
                </b-col>
                <b-col
                    cols="12"
                    lg="6"
                    class="justify-content-end mt-1 mt-lg-0 d-flex"
                >
                  <div class="d-flex bigGap w-75 align-items-center">
                    <b-form-input
                        :placeholder="$t(locale.inputPlaceholder)"
                        v-model="queryParams.search"
                    />
                    <div
                        v-ripple.400
                        v-b-modal.modal-customize
                        class="d-flex text-secondaryColor smallGap"
                        role="button"
                    >
                      <img src="@/assets/icon/dashboardAdd.png" width="20px"/>
                      <p class="mb-0" v-text="$t(locale.customize)"/>
                    </div>
                  </div>
                </b-col>

                <!-- Table -->

                <b-col cols="12" class="mt-2">
                  <div class="scrol-area table-responsive">
                    <div
                        v-if="loadingTable"
                        class="text-center text-danger my-2"
                    >
                      <b-spinner class="align-middle"></b-spinner>
                      <strong>{{ $t('Message.loading') }}...</strong>
                    </div>
                    <table v-else class="table b-table table-hover">
                      <thead class="">
                      <tr class="items-center">
                        <th class="text-left whitespace-nowrap p-2 md:p-0">
                            <span class="text-xs text-gray-400">
                              <b-form-checkbox
                                  v-model="selected"
                                  @change="performAllChecked"
                                  :value="checked"
                                  class="custom-control-primary my-auto"
                              />
                            </span>
                        </th>
                        <th
                            width="100"
                            v-for="(item, i) in contractTableHeader"
                            :key="i"
                            class="text-center whitespace-nowrap c-p-2 md:p-0"
                        >
                          <div
                              class="flex justify-between gap-5 items-center w-full"
                          >
                              <span class="text-xs text-gray-400">{{
                                  renderName(item)
                                }}</span>
                            <span class="flex flex-col">
                                <svg
                                    @click="sortColumn(item)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 cursor-pointer"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                  <path
                                      fill-rule="evenodd"
                                      d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                      clip-rule="evenodd"
                                  />
                                </svg>
                                <svg
                                    @click="sortColumn(`-${item}`)"
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="h-4 w-4 cursor-pointer"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                  <path
                                      fill-rule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                          </div>
                        </th>
                        <th class="text-center whitespace-nowrap p-2 md:p-0">
                          <span class="text-xs text-gray-400">Action</span>
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="data in items" :key="data.uuid">
                        <td>
                            <span>
                              <b-form-checkbox
                                  @change="performSingleChecked"
                                  v-model="data.checkbox"
                              />
                            </span>
                        </td>
                        <td v-for="(item, i) in contractTableHeader" :key="i">
                          <router-link
                              :to="{
                                name: 'editContracts',
                                params: { id: data.uuid },
                              }"
                          >
                              <span
                                  class="whitespace-nowrap text-sm"
                                  v-if="['tags'].includes(item)"
                              >{{ data[item].join(",") }}</span
                              >

                            <span
                                v-else
                                class="whitespace-nowrap text-sm"
                                :class="{
                                  'text-green-500': data.active === true,
                                  'text-yellow-500': data.active === false,
                                  'text-blue-500': data.upcoming === true,
                                  'text-danger': data.category === 'Finished',
                                }"
                            >{{ data[item] }}</span
                            >
                          </router-link>
                        </td>
                        <td>
                          <div
                              class="d-flex justify-content-between smallGap align-items-center px-2"
                          >
                            <div
                                class="flex gap-2"
                                v-if="data.category !== 'Finished'"
                            >
                                <span
                                    role="button"
                                    v-b-tooltip.hover
                                    v-if="data.active"
                                    :title="'Inactive contracts'"
                                    @click="openActivityModal('DEACTIVATE', data)"
                                >
                                  <!-- @click="applyActivityAction(false, 'activity', data)" -->
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      class="text-secondaryColor"
                                      name="pause"
                                  />
                                </span>

                              <span
                                  role="button"
                                  v-b-tooltip.hover
                                  v-if="!data.active"
                                  :title="'Activate contracts'"
                                  @click="openActivityModal('ACTIVATE', data)"
                              >
                                  <!-- @click="applyActivityAction(true, 'activity', data)" -->
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      class="text-secondaryColor"
                                      name="play"
                                  />
                                </span>

                              <span
                                  role="button"
                                  v-b-tooltip.hover
                                  :title="'Terminate contract'"
                                  @click="openModalTerminate(data)"
                              >
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      class="text-secondaryColor"
                                      name="close"
                                  />
                                </span>

                              <span
                                  v-if="
                                    data.scheduled_termination.length > 0 &&
                                    data.scheduled_termination.some(
                                      (item) => !item.handled
                                    )
                                  "
                                  role="button"
                                  v-b-tooltip.hover
                                  @click="openEditModal(data, 'cancel')"
                                  :title="$t(locale.schedulingContract)"
                              >
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      role="button"
                                      class="text-success"
                                      name="calendar-clock"
                                  />
                                </span>

                              <span
                                  v-else
                                  role="button"
                                  v-b-tooltip.hover
                                  @click="openModal(data, 'cancel')"
                                  :title="$t(locale.schedulingContract)"
                              >
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      role="button"
                                      class="text-secondaryColor"
                                      name="calendar-clock"
                                  />
                                </span>
                              <span
                                  role="button"
                                  v-b-tooltip.hover
                                  v-if="
                                    data.scheduled_price_change.length > 0 &&
                                    data.scheduled_price_change.some(
                                      (item) => !item.handled
                                    )
                                  "
                                  @click="openEditModal(data, 'price')"
                                  title="Cancel Schedule Price"
                              >
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      role="button"
                                      class="text-success"
                                      name="tag-arrow-up"
                                  />
                                </span>
                              <span
                                  role="button"
                                  v-b-tooltip.hover
                                  v-else
                                  @click="openModal(data, 'price')"
                                  :title="$t(locale.updateContractPrice)"
                              >
                                  <mdicon
                                      :width="20"
                                      :height="20"
                                      role="button"
                                      class="text-secondaryColor"
                                      name="tag-arrow-up"
                                  />
                                </span>
                            </div>
                            <span
                                role="button"
                                v-b-tooltip.hover
                                @click="openTags(data)"
                                :title="$t(locale.tagContract)"
                            >
                                <mdicon
                                    :width="20"
                                    :height="20"
                                    role="button"
                                    class="text-secondaryColor"
                                    :name="
                                    data.tags && data.tags.length > 0
                                      ? 'tag'
                                      : 'tag-outline'
                                  "
                                />
                              </span>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>

                <b-col cols="12">
                  <b-row
                      class="justify-content-center justify-content-lg-between"
                  >
                    <b-col cols="12" sm="6" class="d-flex align-items-end">
                      <div
                          class="d-flex bigGap justify-content-between align-items-center"
                      >
                        <p class="mb-0 h6" v-text="$t(locale.action)"/>
                        <div class="">
                          <b-form-select
                              id="perPageSelect"
                              size="sm"
                              inline
                              v-model="actionLable"
                          >
                            <b-form-select-option
                                v-for="(option, index) in optionsAction"
                                :key="index"
                                :value="option.value"
                                v-text="$t(option.label)"
                            />
                          </b-form-select>
                        </div>

                        <b-button
                            size="sm"
                            variant="primary"
                            :disabled="loadingAction"
                            @click="applyMultiAction(actionLable)"
                            v-text="
                            loadingAction
                              ? $t('Message.loading')
                              : $t(locale.apply)
                          "
                        />

                        <b-button
                            size="sm"
                            @click="openTags(null)"
                            variant="primary"
                            v-text="$t(locale.tag)"
                        />
                      </div>
                    </b-col>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-end flex-sm-column justify-content-between justify-content-sm-end"
                    >
                      <paginate
                          v-model="pagination.currentPage"
                          :page-count="pagination.totalProducts"
                          :click-handler="firePagination"
                          :prev-text="'<'"
                          :next-text="'>'"
                          :page-class="'page-item c-mr-1 border-unset'"
                          prev-link-class="page-link c-mr-1 border-unset"
                          next-link-class="page-link c-mr-1 border-unset"
                          next-class="next-item"
                          prev-class="prev-item"
                          page-link-class="page-link c-mr-1 border-unset"
                          active-class="active c-mr-1 border-unset"
                          :container-class="'pagination  b-pagination'"
                      >
                      </paginate>
                      <!-- <b-pagination
                                                v-model="pagination.currentPage"
                                                :total-rows="
                                                    pagination.totalProducts
                                                "
                                                :per-page="queryParams.limit"
                                                first-number
                                                last-number
                                                class="mb-0 mt-1 mt-sm-0 order-2 order-sm-1"
                                                prev-class="prev-item"
                                                next-class="next-item"
                                            >
                                                <template #prev-text>
                                                    <feather-icon
                                                        icon="ChevronLeftIcon"
                                                        size="18"
                                                    />
                                                </template>
                                                <template #next-text>
                                                    <feather-icon
                                                        icon="ChevronRightIcon"
                                                        size="18"
                                                    />
                                                </template>
                                            </b-pagination> -->

                      <div
                          class="d-flex justify-content-between justify-content-sm-end smallGap align-items-center mt-1 w-100 order-1 order-sm-2"
                      >
                        <p
                            class="mb-0 d-none d-sm-block h6"
                            v-text="$t(locale.showPerPage)"
                        />
                        <div class="">
                          <b-form-select
                              v-model="queryParams.limit"
                              @change="switchLimit"
                              id="perPageSelect"
                              size="sm"
                              inline
                              :options="pageOptions"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </div>

    <Modal
        id="modal-contract-cancel"
        :title="$t(locale.schedulingContract)"
        :hideFooter="true"
        :closeEvent="closeModal"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'ok-title': $t(locale.save),
            }" -->
      <!-- :okEvent="scheduleContract" -->
      <ValidationObserver ref="scheduleForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in schedulingContract"
            :rules="form.rules"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label class="h6 font-weight-bold">
              {{ $t(form.label) }}
              <span class="text-danger">*</span>
            </label>

            <template v-if="index == 0">
              <date-picker
                  v-model="form.value"
                  valueType="format"
                  class="w-100"
                  prefix-class="xmx"
                  :placeholder="$t(form.label)"
              />
              <small class="text-danger" v-text="errors[0]"/>
            </template>

            <template v-else>
              <!-- <b-form-select
                            v-model="form.value"
                            :options="form.option">
                                <template #options="{label}">
                                    {{ $t(label) }}
                                </template>
                            </b-form-select> -->

              <v-select
                  v-model="form.value"
                  :options="form.option"
                  :placeholder="$t(form.label)"
                  @input="
                  (e) => {
                    e ? (form.value = e.value) : (form.value = '');
                  }
                "
                  class="resource-selector d-inline-block"
              >
                <template #option="{ label }">
                  {{ $t(label) }}
                </template>
              </v-select>
            </template>
          </div>
        </ValidationProvider>

        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-contract-cancel')"
          >
            <span v-text="$t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="runSchedule('cancel')"
          >
            <span
                v-text="
                sendingModalAction ? $t('Message.loading') : $t(locale.save)
              "
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal
        id="modal-updateContractPrice"
        :title="$t(locale.updateContractPrice)"
        :hideFooter="true"
        :closeEvent="closeModal"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'ok-title': this.$t(locale.save),
            }"
            :okEvent="updateContractPriceForm" -->
      <ValidationObserver ref="scheduleForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in updateContractPrice"
            :rules="form.rules"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label class="h6 font-weight-bold">
              {{ $t(form.label) }}
              <span class="text-danger">*</span>
            </label>

            <template v-if="form.type == 'date'">
              <date-picker
                  v-model="form.value"
                  valueType="format"
                  class="w-100"
                  prefix-class="xmx"
                  :placeholder="$t(form.label)"
              />
              <small class="text-danger" v-text="errors[0]"/>
            </template>

            <template v-else-if="form.type == 'select'">
              <v-select
                  v-model="form.value"
                  :options="form.option"
                  :placeholder="$t(form.label)"
                  @input="
                  (e) => {
                    e ? (form.value = e.value) : (form.value = '');
                  }
                "
                  class="resource-selector d-inline-block"
              >
                <template #option="{ label }">
                  {{ $t(label) }}
                </template>
              </v-select>
            </template>

            <template v-else-if="form.type == 'input'">
              <b-form-group>
                <b-form-input
                    v-model="form.value"
                    :placeholder="$t(form.label)"
                />
              </b-form-group>
            </template>
          </div>
        </ValidationProvider>
        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-updateContractPrice')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="runSchedule('price')"
          >
            <span
                v-text="
                sendingModalAction ? $t('Message.loading') : $t(locale.save)
              "
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal
        id="modal-updateContractPrice-update"
        :title="$t(locale.updateContractPrice)"
        :hideFooter="true"
        :closeEvent="closeModal"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'ok-title': this.$t(locale.save),
            }"
            :okEvent="updateContractPriceForm" -->
      <ValidationObserver ref="scheduleForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in updateContractPrice"
            :rules="form.rules"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label class="h6 font-weight-bold">
              {{ $t(form.label) }}
              <span class="text-danger">*</span>
            </label>

            <template v-if="form.type == 'date'">
              <date-picker
                  v-model="form.value"
                  valueType="format"
                  class="w-100"
                  prefix-class="xmx"
                  :placeholder="$t(form.label)"
              />
              <small class="text-danger" v-text="errors[0]"/>
            </template>

            <template v-else-if="form.type == 'select'">
              <v-select
                  v-model="form.value"
                  :options="form.option"
                  :placeholder="$t(form.label)"
                  @input="
                  (e) => {
                    e ? (form.value = e.value) : (form.value = '');
                  }
                "
                  class="resource-selector d-inline-block"
              >
                <template #option="{ label }">
                  {{ $t(label) }}
                </template>
              </v-select>
            </template>

            <template v-else-if="form.type == 'input'">
              <b-form-group>
                <b-form-input
                    v-model="form.value"
                    :placeholder="$t(form.label)"
                />
              </b-form-group>
            </template>
          </div>
        </ValidationProvider>
        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-updateContractPrice-update')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="runUpdateSchedule('price')"
          >
            <span
                v-text="sendingModalAction ? $t('Message.loading') : 'Update'"
            />
          </b-button>
          <b-button
              variant="danger"
              size="md"
              :disabled="sendingModalActionDelete"
              @click="runDeleteSchedule('price')"
          >
            <span
                v-text="
                sendingModalActionDelete ? $t('Message.loading') : 'Delete'
              "
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <Modal
        id="modal-contract-cancel-update"
        :title="$t(locale.schedulingContract)"
        :hideFooter="true"
        :closeEvent="closeModal"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'ok-title': $t(locale.save),
            }" -->
      <!-- :okEvent="scheduleContract" -->
      <ValidationObserver ref="scheduleForm">
        <ValidationProvider
            #default="{ errors }"
            v-for="(form, index) in schedulingContract"
            :rules="form.rules"
            :name="$t(form.label)"
            :key="index"
        >
          <div class="d-flex flex-column mt-1">
            <label class="h6 font-weight-bold">
              {{ $t(form.label) }}
              <span class="text-danger">*</span>
            </label>

            <template v-if="index == 0">
              <date-picker
                  v-model="form.value"
                  valueType="format"
                  class="w-100"
                  prefix-class="xmx"
                  :placeholder="$t(form.label)"
              />
              <small class="text-danger" v-text="errors[0]"/>
            </template>

            <template v-else>
              <!-- <b-form-select
                            v-model="form.value"
                            :options="form.option">
                                <template #options="{label}">
                                    {{ $t(label) }}
                                </template>
                            </b-form-select> -->

              <v-select
                  v-model="form.value"
                  :options="form.option"
                  :placeholder="$t(form.label)"
                  @input="
                  (e) => {
                    e ? (form.value = e.value) : (form.value = '');
                  }
                "
                  class="resource-selector d-inline-block"
              >
                <template #option="{ label }">
                  {{ $t(label) }}
                </template>
              </v-select>
            </template>
          </div>
        </ValidationProvider>

        <div class="flex justify-end gap-3 mt-2">
          <b-button
              variant="outline-danger"
              size="md"
              @click="$bvModal.hide('modal-contract-cancel-update')"
          >
            <span v-text=" $t('Message.cancel')"/>
          </b-button>
          <b-button
              variant="primary"
              size="md"
              :disabled="sendingModalAction"
              @click="runUpdateSchedule('cancel')"
          >
            <span
                v-text="sendingModalAction ? $t('Message.loading') : 'Update'"
            />
          </b-button>
          <b-button
              variant="danger"
              size="md"
              :disabled="sendingModalActionDelete"
              @click="runDeleteSchedule('cancel')"
          >
            <span
                v-text="
                sendingModalActionDelete ? $t('Message.loading') : 'Delete'
              "
            />
          </b-button>
        </div>
      </ValidationObserver>
    </Modal>

    <!-- Tags -->
    <Modal
        id="modal-tagContract"
        :title="$t(locale.tagContract)"
        :closeEvent="closeModal"
        :hideFooter="true"
    >
      <!-- :options="{
                size: 'sm',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'hide-footer': true,
            }"
            :okEvent="okEvent" -->
      <div class="scrol-area" style="height: 200px">
        <div class="grid grid-cols-2 md:grid-cols-3 gap-3">
          <div v-for="data in tagResults" :key="data.uuid">
            <div class="d-flex custom-border bg-white rounded-md c-p-2">
              <b-form-checkbox
                  :value="data.name"
                  v-model="selectedTags"
                  class="text-white"
              >
                {{ data.name }}
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex smallgap pt-1 justify-content-between">
        <div>
          <b-form-input
              v-model="tagData"
              size="sm"
              :placeholder="$t(locale.addTag)"
          />
        </div>

        <div>
          <b-button
              size="sm"
              @click="saveTag"
              variant="primary"
              v-text="savingTag ? $t('Message.loading') : $t(locale.save)"
          />
        </div>
      </div>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-tagContract')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="assignTagData"
        >
          <span
              v-text="
              sendingModalAction ? $t('Message.loading') : $t(locale.save)
            "
          />
        </b-button>
      </div>
    </Modal>

    <Modal
        id="modal-customize"
        :title="
        $t(locale.modalCustomize, {
          pageTitle: $t($route.meta.pageTitle),
        })
      "
        :hideFooter="true"
    >
      <!-- :options="{
                size: 'lg',
                'ok-variant': 'thirdColor',
                'cancel-variant': 'outline-danger',
                'no-close-on-backdrop': true,
                'hide-footer': true,
                'ok-title': this.$t(locale.update),
            }" -->
      <div class="scrol-area" style="height: 250px">
        <draggable v-model="contractTableHeader">
          <b-card
              v-for="(data, index) in contractTableHeader"
              :key="index"
              no-body
              class="border mb-1"
              style="cursor: grab"
          >
            <b-card-text class="p-1 d-flex bigGap">
              <mdicon :width="20" :height="20" name="flip-to-back"/>

              <p class="mb-0" v-text="renderName(data)"/>
            </b-card-text>
          </b-card>
        </draggable>
      </div>

      <div class="pt-1">
        <p class="" v-text="$t(locale.chooseColumn)"/>
        <b-row class="scrol-area">
          <b-col
              cols="12"
              lg="6"
              v-for="(column, index) in Object.keys(CONTRACT_CUSTOMIZE)"
              :key="index"
          >
            <div class="mt-1">
              <b-form-checkbox v-model="contractTableHeader" :value="column">
                <!-- {{ CONTRACT_CUSTOMIZE[column] }} -->
                {{ renderName(column) }}
              </b-form-checkbox>
            </div>
          </b-col>
        </b-row>
      </div>

      <div class="d-flex smallgap pt-1 justify-content-end">
        <div>
          <b-button
              variant="primary"
              @click="runCustomization"
              v-text="
              sendingModalAction ? $t('Message.loading') : $t(locale.update)
            "
          />
        </div>
      </div>
    </Modal>

    <Modal
        id="modal-multi-action"
        :hideFooter="true"
        :title="choosenData.mainTitle"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ choosenData.banner }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            @click="$bvModal.hide('modal-multi-action')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            variant="primary"
            size="md"
            :disabled="sendingModalAction"
            @click="performModalAction"
        >
          <span
              v-text="sendingModalAction ? $t('Message.loading') :  $t('Message.proceed')"
          />
        </b-button>
      </div>
    </Modal>
    <Modal
        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from "vee-validate";
import DatePicker from "vue2-datepicker";
import vSelect from "vue-select";
import {dateMixins} from "@/mixins/dateMixins";
import useAppConfig from "@core/app-config/useAppConfig";
import Paginate from "vuejs-paginate";
import {
  CONTRACT_CUSTOMIZATION,
  renderCustomizeName,
} from "./../customization/Constants";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import accessDenied from "@core/components/permission/accessDenied";
import {mapGetters} from "vuex";

export default {
  name: "invoicesOverview",
  mixins: [dateMixins],
  props: {
    fetch: {
      type: Boolean,
      required: false,
    },
  },
  components: {
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    paginate: Paginate, accessDenied
  },

  data() {
    return {
      config: useAppConfig(),
      totalProducts: "",
      accessDenied: true,
      loadingState: true,
      loadingAction: false,
      savingTag: false,
      hiddenTable: false,
      choosenData: {},
      sendingModalAction: false,
      sendingModalActionDelete: false,
      activeLabel: "all",
      actionLable: "",
      items: [],
      CONTRACT_CUSTOMIZE: {...CONTRACT_CUSTOMIZATION},
      contractCustomization: [],
      selectedTags: [],
      queryParams: {
        limit: 50,
        // category: '',
        // customize: 'yes',
      },
      pageOptions: [50, 100, 150, 200],
      pagination: {
        currentPage: 1,
        totalProducts: 0,
      },
      tagData: "",
      loadingTable: false,
      categoryValue: "All",
      selected: false,
      checked: "selected",
      selectedData: {},
      contractTableHeader: [],
      schedulingContract: [
        {
          label: "contractsCard.dateCancel",
          rules: "required",
          type: "date",
          fieldValue: "date_to_cancel",
          value: "",
        },
        {
          label: "contractsCard.typeCanceling",
          rules: "required",
          type: "select",
          option: [
            {
              label: "CustomersCard.inactive",
              value: "inactivate",
            },
            {
              label: "contractsCard.terminate",
              value: "terminate",
            },
          ],
          value: "",
          fieldValue: "action",
        },
      ],

      categoryButton: [
        {
          label: "invoicesCard.all",
          variant: "indigo",
          value: "All",
        },
        {
          label: "CustomersCard.inactive",
          variant: "warning",
          upcoming: false,
          value: "Inactive",
        },
        {
          label: "contractsCard.terminated",
          variant: "danger",
          upcoming: false,
          value: "Finished",
        },
        {
          label: "CustomersCardEdit.firstForm.active",
          variant: "success",
          upcoming: false,
          value: "Active",
        },
        {
          label: "contractsCard.upcoming",
          variant: "thirdColor",
          upcoming: 1,
          value: "Upcoming",
        },
      ],

      optionsAction: [
        {
          label: "contractsCard.createInvoice",
          value: "createinvoice",
        },
        {
          label: "contractsCard.finished",
          value: "finish",
        },
        {
          label: "contractsCard.increaseInvoiceCount",
          value: "increaseinvoicecount",
        },
        {
          label: "contractsCard.scheduleContractCancelling",
          value: "scheduleContractCancelling",
        },
        {
          label: "contractsCard.updateContractPrices",
          value: "updateContractPrice",
        },
      ],

      updateContractPrice: [
        {
          label: "contractsCard.priceChangePercent",
          rules: "required",
          type: "date",
          value: "",
          fieldValue: "date_to_update",
        },
        {
          label: "contractsCard.typePerPrice",
          rules: "required",
          type: "select",
          option: [
            {
              label: "contractsCard.increase",
              value: "increase",
            },
            {
              label: "contractsCard.decrease",
              value: "decrease",
            },
          ],
          value: "",
          fieldValue: "change_type",
        },
        {
          label: "contractsCard.priceChangePercent",
          rules: "required",
          type: "input",
          value: 0,
          fieldValue: "percentage",
        },
      ],

      fields: [
        {
          key: "checkbox",
          tdClass: "bTableMainThStyle",
          thStyle: {width: "20px", padding: "0.72rem 10px"},
        },
        // {
        //     key: 'document_number',
        //     label: 'invoicesCard.table.number',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 10px',
        //         width: '70px',
        //     },
        // },

        // {
        //     key: 'customer_number',
        //     label: 'CustomersCard.table.custNumber',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 0',
        //         width: '128px',
        //     },
        // },
        // {
        //     key: 'customer_name',
        //     label: 'CustomersCard.table.name',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 0',
        //         width: '80px',
        //     },
        // },
        // {
        //     key: 'contract_date',
        //     label: 'dashboardCard.table.invoiceDateHead',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 0',
        //         width: '100px',
        //     },
        // },

        // {
        //     key: 'contract_length',
        //     label: 'invoicesCard.table.balance',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 0',
        //         width: '70px',
        //     },
        // },
        // {
        //     key: 'last_invoice_date',
        //     label: 'InvoiceCardCustomerEdit.currency',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 0',
        //         width: '75px',
        //     },
        // },

        // {
        //     key: 'invoice_interval',
        //     label: 'InvoiceCardCustomerEdit.currency',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 0',
        //         width: '75px',
        //     },
        // },
        // {
        //     key: 'total',
        //     label: 'HomeCard.Total',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 0',
        //         width: '50px',
        //     },
        // },

        // {
        //     key: 'category',
        //     label: 'dashboardCard.table.dueDateHead',
        //     tdClass: 'bTableThStyle',
        //     thStyle: {
        //         padding: '0.72rem 0',
        //         width: '90px',
        //     },
        // },

        {
          key: "action",
          label: "CustomersCard.table.action",
          tdClass: "bTableThStyle",
          thStyle: {
            padding: "0.72rem 0",
            width: "150px",
          },
        },
      ],

      locale: {
        inputPlaceholder: "contractsCard.searchLabel",
        showPerPage: "golfRelatedCard.showPerPage",
        apply: "golfRelatedCard.apply",
        action: "golfRelatedCard.action",
        inactiveContract: "contractsCard.inactiveContract",
        terminateContract: "contractsCard.terminateContract",
        schedulingContract: "contractsCard.schedulingContract",
        updateContractPrice: "contractsCard.updateContractPrice",
        tagContract: "contractsCard.tagContract",
        save: "save",
        tag: "CustomersCard.tag",
        addTag: "contractsCard.addTag",
        modalCustomize: "customizeColumn",
        update: "UsersCard.update",
        chooseColumn: "createContract.chooseColumn",
        customize: "CustomersCard.customize",
      },
      tagResults: [],
      single: false,
      multi: false,
    };
  },

  watch: {
    "queryParams.search": {
      handler(value) {
        this.queryParams.search = value;
        this.getContracts();
      },
    },
    fetch: function (n, o) {
      if (n) {
        this.getContracts();
      }
    },
  },
  computed: {
    message() {
      return this.GET_LOCALE === "sv" ? "message" : "eng_message";
    },
    features() {
     return this.getLang === "sv" ? "features" : "features";
    },
    getLang() {
      return this.config.lang.value;
    },
    fieldSubstr() {
      return this.fields.substr(1, this.fields.length - 2);
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    closeModal() {
    },

    okEvent(event) {
      event.preventDefault();
    },
    switchLimit(value) {
      this.queryParams.limit = value;
      this.getContracts();
    },
    runCustomization() {
      if (this.contractTableHeader.length > 0) {
        this.sendingModalAction = true;
        const payload = {
          columns: this.contractTableHeader,
        };

        this.$useJwt
            .customization({URL: "contract", method: "put"}, payload)
            .then((res) => {
              this.getCustomizeHeader();
              this.sendingModalAction = false;
              this.getContracts({URL: "", method: "get"});
              this.$bvModal.hide("modal-customize");
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data.message,
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    saveTag() {
      if (this.tagData !== "") {
        this.savingTag = true;
        const payload = {
          type: "CONTRACT",
          name: this.tagData,
        };
        this.$useJwt
            .customTags({URL: "/", method: "post"}, payload)
            .then((res) => {
              this.savingTag = false;
              this.getTags();
              this.tagData = "";
            })
            .catch((err) => {
              this.savingTag = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    getTags() {
      this.$useJwt
          .customTags({URL: "?type=CONTRACT", method: "get"})
          .then((res) => {
            this.tagResults = res.data.results;
          });
    },
    selectedRow(value) {
      this.$router.push({
        name: "editContracts",
        params: {id: value.uuid},
      });
    },
    performAllChecked(e) {
      this.items.forEach((element) => {
        element.checkbox = this.selected === "selected" ? true : false;
      });
    },
    performSingleChecked(e) {
    },
    openTags(item = null) {
      if (item) {
        item.checkbox = true;
        this.selectedTags = item.tags;
        this.$bvModal.show("modal-tagContract");
      } else {
        this.selectedTags = [];
        const filter = this.items.filter((el) => el.checkbox);
        if (filter.length > 0) {
          this.$bvModal.show("modal-tagContract");
        }
      }
    },
    getSelectValue(e) {
    },
    renderName(key) {
      return renderCustomizeName(this.getLang, key, CONTRACT_CUSTOMIZATION);
    },
    openModal(data, mode) {
      this.clearForm();
      this.selectedData = data;
      this.multi = false;
      if (mode === "price") {
        this.$bvModal.show("modal-updateContractPrice");
      } else {
        this.$bvModal.show("modal-contract-cancel");
      }
    },
    openEditModal(data, mode) {
      this.selectedData = data;
      this.multi = false;
      if (mode === "price") {
        this.updateContractPrice.map((el) => {
          if (data.scheduled_price_change[0][el.fieldValue]) {
            el.value = data.scheduled_price_change[0][el.fieldValue];
          }
        });
        this.$bvModal.show("modal-updateContractPrice-update");
      } else {
        this.schedulingContract.map((el) => {
          if (data.scheduled_termination[0][el.fieldValue]) {
            el.value = data.scheduled_termination[0][el.fieldValue];
          }
        });
        this.$bvModal.show("modal-contract-cancel-update");
      }
    },
    clearForm() {
      this.updateContractPrice.map((el) => {
        el.value = "";
      });
      this.schedulingContract.map((el) => {
        el.value = "";
      });
    },
    runSchedule(type) {
      // event.preventDefault()
      this.$refs.scheduleForm.validate().then((res) => {
        if (res) {
          const payload = {};
          if (type === "cancel") {
            this.schedulingContract.map((el) => {
              payload[el.fieldValue] = el.value;
            });
          } else {
            this.updateContractPrice.map((el) => {
              payload[el.fieldValue] = el.value;
            });
          }
          if (!this.multi) {
            payload["contracts"] = [this.selectedData.uuid];
            payload["document_number"] = this.selectedData.document_number;
          } else {
            const filter = this.items.filter((el) => el.checkbox);
            if (filter.length > 0) {
              payload["selected"] = [];
              payload["contracts"] = [];

              filter.map((el) => {
                if (type === "cancel") {
                  delete payload["selected"];
                  payload["contracts"].push(el.uuid);
                } else {
                  delete payload["contracts"];
                  payload["selected"].push(el.uuid);
                }
              });
            }
          }
          const mode = {URL: "", method: "post"};

          const validateDate =
              type === "cancel"
                  ? this.validatePresentDate(payload["date_to_cancel"])
                  : this.validatePresentDate(payload["date_to_update"]);

          if (validateDate) {
            if (type === "cancel") {
              mode.URL = "schedules/contract_termination/";
            } else {
              if (!this.multi) {
                mode.URL = "schedules/contract_price_change";
              } else {
                mode.URL = "contracts/price_update";
                mode.method = "put";
              }
            }

            this.sendingModalAction = true;

            this.$useJwt
                .customBaseURLOnly(mode, payload)
                .then((res) => {
                  this.getContracts();
                  if (type === "cancel") {
                    this.$bvModal.hide("modal-contract-cancel");
                  } else {
                    this.$bvModal.hide("modal-updateContractPrice");
                  }
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.message],
                      "CheckIcon",
                      "success"
                  );
                })
                .catch((err) => {
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                });
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Select a date ahead",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      });
    },
    getCustomizeHeader() {
      this.$useJwt
          .customization({URL: "contract", method: "get"})
          .then((res) => {
            const fields = [
              {
                key: "checkbox",
                // label: 'articleCard.table.articleNumber',
                tdClass: "bTableThStyle",
                thStyle: {
                  padding: "0.72rem 10px",
                  width: "50px",
                },
              },
              {
                key: "action",
                label: "CustomersCard.table.action",
                tdClass: "bTableThStyle",
                thStyle: {
                  padding: "0.72rem 10px",
                  width: "100px",
                },
              },
            ];
            if (res.data.data.length > 0) {
              this.contractTableHeader = res.data.data;
              res.data.data.map((el, i) => {
                const obj = {
                  key: el,
                  label: el,
                  tdClass: "bTableThStyle",
                  thStyle: {
                    padding: "0.72rem 10px",
                    width: "140px",
                  },
                };
                fields.splice(parseInt(i + 1), 0, obj);
              });
            } else {
              Object.keys(CONTRACT_CUSTOMIZATION).forEach((el) => {
                this.contractTableHeader.push(el);
              });
            }
            // this.fields = fields
          });
    },
    assignTagData() {
      const payload = {
        tags: Array.isArray(this.selectedTags)
            ? this.selectedTags
            : [this.selectedTags],
        selected: [],
      };
      const filter = this.items.filter((el) => el.checkbox);
      if (filter.length > 0) {
        this.sendingModalAction = true;
        filter.map((el) => {
          payload.selected.push(el.uuid);
        });

        this.$useJwt
            .getContracts({URL: "/bulktag", method: "put"}, payload)
            .then((res) => {
              this.selectedTags = [];
              this.sendingModalAction = false;
              this.$bvModal.hide("modal-tagContract");
              this.getContracts({URL: "", method: "get"});
              this.popupMsg(
                  this.$t("Message.Success"),
                  res.data[this.message],
                  "CheckIcon",
                  "success"
              );
            })
            .catch((err) => {
              this.sendingModalAction = false;
              this.popupMsg(
                  this.$t("Message.Failed"),
                  err.response.data[this.message],
                  "AlertTriangleIcon",
                  "danger"
              );
            });
      }
    },
    applyMultiAction(value) {
      if (
          value !== "scheduleContractCancelling" &&
          value !== "updateContractPrice"
      ) {
        const payload = {
          contracts: [],
          action: value,
        };
        const filter = this.items.filter((el) => el.checkbox);
        if (filter.length > 0) {
          this.loadingAction = true;
          filter.map((el) => {
            payload.contracts.push(el.uuid);
          });

          this.$useJwt
              .getContracts({URL: "/bulkactions", method: "put"}, payload)
              .then((res) => {
                this.actionLable = "";
                this.loadingAction = false;
                this.getContracts({URL: "", method: "get"});
                this.popupMsg(
                    this.$t("Message.Success"),
                    res.data[this.message],
                    "CheckIcon",
                    "success"
                );
              })
              .catch((err) => {
                this.loadingAction = false;
                this.popupMsg(
                    this.$t("Message.Failed"),
                    err.response.data[this.message],
                    "AlertTriangleIcon",
                    "danger"
                );
              });
        } else {
          this.popupMsg(
              this.$t("Message.Failed"),
              "Select at least a contract",
              "AlertTriangleIcon",
              "danger"
          );
        }
      } else {
        const filter = this.items.filter((el) => el.checkbox);
        if (filter.length > 0) {
          this.multi = true;
          if (value === "updateContractPrice") {
            this.$bvModal.show("modal-updateContractPrice");
          } else {
            this.$bvModal.show("modal-contract-cancel");
          }
        } else {
          this.popupMsg(
              this.$t("Message.Failed"),
              "Select at least a contract",
              "AlertTriangleIcon",
              "danger"
          );
        }
      }
    },
    selectedStatus(buttonValue) {
      const {value} = buttonValue;
      this.categoryValue = value;
      if (this.search == "") delete this.queryParams.search;

      if (value == "Upcoming") {
        delete this.queryParams.category;
        this.queryParams = {
          upcoming: buttonValue.upcoming,
        };
      } else if (value == "Active") {
        this.queryParams = {
          upcoming: buttonValue.upcoming,
          category: value,
        };
      } else if (value == "Finished") {
        this.queryParams = {
          upcoming: buttonValue.upcoming,
          category: value,
        };
      } else if (value == "All") {
        delete this.queryParams.upcoming;
        delete this.queryParams.category;
      } else {
        this.queryParams = {
          upcoming: buttonValue.upcoming,
          category: value,
        };
      }

      delete this.queryParams.limit;

      this.getContracts();
    },
    sortColumn(item) {
      this.queryParams.ordering = item;
      delete this.queryParams.search;
      this.getContracts();
    },
    openActivityModal(mode, data) {
      this.choosenData = data;
      this.choosenData["banner"] =
          mode === "ACTIVATE"
              ? "Är du säker på att du vill aktivera detta avtal?"
              : "Är du säker på att du vill inaktivera detta avtal?";
      this.choosenData["mainTitle"] =
          mode === "ACTIVATE" ? "Aktivera avtal" : "Inaktivera avtal";
      this.choosenData["mode"] = mode;
      this.$bvModal.show("modal-multi-action");
    },
    openModalTerminate(item) {
      this.choosenData = item;
      this.choosenData["banner"] =
          "Är du säker på att du vill avsluta detta avtal?";
      this.choosenData["mainTitle"] = "Avsluta avtal";
      this.choosenData["mode"] = "TERMINATE";
      this.$bvModal.show("modal-multi-action");
    },
    applyActivityAction(actionValue, mode, item) {
      const payload = {};
      let urlMode = {};
      if (mode === "activity") {
        payload["active"] = actionValue;
        urlMode = {URL: `/${item.uuid}`, method: "put"};
      }
      if (mode === "terminate") {
        urlMode = {URL: `/${item.uuid}/finish`, method: "put"};
      }

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: this.$t("Message.wait"),
          variant: "warning",
          // text: this.$t('Message.subscriptionProcess'),
          waiting: true,
        },
      });

      this.$useJwt
          .getContracts(urlMode, payload)
          .then((res) => {
            this.getContracts({URL: "", method: "get"});
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.loadingAction = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    performModalAction() {
      this.sendingModalAction = true;
      if (this.choosenData.mode === "ACTIVATE") {
        this.applyActivityAction(true, "activity", this.choosenData);
      } else if (this.choosenData.mode === "DEACTIVATE") {
        this.applyActivityAction(false, "activity", this.choosenData);
      } else {
        this.applyActivityAction(false, "terminate", this.choosenData);
      }
      this.sendingModalAction = false;
      this.$bvModal.hide("modal-multi-action");
    },
    firePagination(number) {
      this.pagination.currentPage = number;
      this.queryParams.page = number;
      this.getContracts();
    },
    getContracts() {
      this.loadingTable = true;
      this.$useJwt
          .getContracts({URL: "", method: "get"}, {params: this.queryParams})
          .then((res) => {
            const {data} = res.data;

            this.items = data.map((el) => {
              return {
                ...el,
                checkbox: false,
              };
            });

            this.loadingState = false;
            this.loadingTable = false;
            this.hiddenTable = true;

            this.pagination = {
              totalProducts: res.data.total_pages,
            };

            this.queryParams.limit = res.data.limit;
          }).catch(err => {
        this.loadingState = false;
        this.loadingTable = false;
        this.hiddenTable = true;
        if (err.response.status === 403) {
         window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
        }
      });
    },
    runDeleteSchedule(mode) {
      this.sendingModalActionDelete = true;
      const payload = {URL: "", method: "delete"};
      if (mode === "price") {
        payload.URL = `schedules/contract_price_change/${this.selectedData.scheduled_price_change[0].uuid}`;
      } else {
        payload.URL = `schedules/contract_termination/${this.selectedData.scheduled_termination[0].uuid}`;
      }

      this.$useJwt
          .customBaseURLOnly(payload)
          .then((res) => {
            this.getContracts();
            if (mode === "cancel") {
              this.$bvModal.hide("modal-contract-cancel-update");
            } else {
              this.$bvModal.hide("modal-updateContractPrice-update");
            }
            this.sendingModalActionDelete = false;
            this.popupMsg(
                this.$t("Message.Success"),
                res.data[this.message],
                "CheckIcon",
                "success"
            );
          })
          .catch((err) => {
            this.sendingModalActionDelete = false;
            this.popupMsg(
                this.$t("Message.Failed"),
                err.response.data[this.message],
                "AlertTriangleIcon",
                "danger"
            );
          });
    },
    runUpdateSchedule(type) {
      // event.preventDefault()
      this.$refs.scheduleForm.validate().then((res) => {
        if (res) {
          const payload = {};
          if (type === "cancel") {
            this.schedulingContract.map((el) => {
              payload[el.fieldValue] = el.value;
            });
          } else {
            this.updateContractPrice.map((el) => {
              payload[el.fieldValue] = el.value;
            });
          }
          payload["contracts"] = [this.selectedData.uuid];
          payload["document_number"] = this.selectedData.document_number;

          const mode = {URL: "", method: "put"};

          const validateDate =
              type === "cancel"
                  ? this.validatePresentDate(payload["date_to_cancel"])
                  : this.validatePresentDate(payload["date_to_update"]);

          if (validateDate) {
            if (type === "cancel") {
              mode.URL = `schedules/contract_termination/${this.selectedData.scheduled_termination[0].uuid}`;
            } else {
              mode.URL = `schedules/contract_price_change/${this.selectedData.scheduled_price_change[0].uuid}`;
            }

            this.sendingModalAction = true;

            this.$useJwt
                .customBaseURLOnly(mode, payload)
                .then((res) => {
                  this.getContracts();
                  if (type === "cancel") {
                    this.$bvModal.hide("modal-contract-cancel-update");
                  } else {
                    this.$bvModal.hide("modal-updateContractPrice-update");
                  }
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Success"),
                      res.data[this.message],
                      "CheckIcon",
                      "success"
                  );
                })
                .catch((err) => {
                  this.sendingModalAction = false;
                  this.popupMsg(
                      this.$t("Message.Failed"),
                      err.response.data[this.message],
                      "AlertTriangleIcon",
                      "danger"
                  );
                });
          } else {
            this.popupMsg(
                this.$t("Message.Failed"),
                "Select a date ahead",
                "AlertTriangleIcon",
                "danger"
            );
          }
        }
      });
    },
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    this.getCustomizeHeader();
    this.getContracts();
    this.getTags();

  },
};
</script>

<style scoped lang="scss">
@import "~@core/scss/base/bootstrap-extended/_variables.scss";

.scrol-area {
  overflow: auto;
  scrollbar-color: $primary transparent;
}

.resource-selector {
  width: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/datepicker.scss";

.table th {
  padding: 0.4rem !important;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table tbody,
td {
  padding: 0.4rem !important;
  // padding-bottom: .4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6 !important;
  border-bottom: 1px solid #dee2e6 !important;
}
</style>
