<template>
  <div>
    <b-row align-h="center">
      <b-col cols="6">
        <p class="mb-0 h4" v-text="$t(locale.title)"/>
      </b-col>
      <b-col cols="6" class="d-flex justify-content-end">
                <span
                    role="button"
                    @click="$router.push({name: 'createContracts'})"
                >
                    <mdicon
                        :width="25"
                        :height="25"
                        class="text-secondaryColor"
                        name="plus-box"
                    />
                </span>
        <span role="button" @click="synContracts">
                    <mdicon
                        :width="25"
                        :height="25"
                        class="text-secondaryColor"
                        name="refresh"
                    />
                </span>
      </b-col>
    </b-row>

    <ContractOverview :fetch="fetch"/>
  </div>
</template>

<script>
import ContractOverview from '@/@core/components/accountingData/contract/contractOverview.vue';
import bus from '@/bus';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';

export default {
  name: 'contractsOverview',
  components: {
    ContractOverview,
  },

  data() {
    return {
      fetch: false,
      locale: {
        title: 'contractsCard.contractOverview',
      },
    };
  },
  methods: {
    synContracts() {
      this.fetch = false
      // this.$toast({
      //   component: ToastificationContentVue,
      //   props: {
      //     title: this.$t('Message.wait'),
      //     variant: 'warning',
      //     // text: this.$t('Message.subscriptionProcess'),
      //     // waiting: true,
      //     timeout: 1500,
      //   },
      // });

      this.$useJwt.customSync({URL: '/contracts', method: 'get'})
          .then(res => {
            this.fetch = true
            this.$emit('close-toast')
            this.popupMsg(
                this.$t('Message.Success'),
                res.data.message,
                'CheckIcon',
                'success'
            );
          })
          .catch(err => {
            this.loadingAction = false
            this.popupMsg(
                this.$t('Message.Failed'),
                err.response.data[this.message],
                'AlertTriangleIcon',
                'danger'
            );
          })
    },

  }
};
</script>
